.my-account-wrapper {
  margin-bottom: 16px;

  @include desktopExperience {
    display: flex;

    aside {
      align-self: flex-start;
      flex-shrink: 0;
    }

    .main-content {
      flex-grow: 1;
    }
  }

  p.title {
    margin: 0 0 12px 0;
    color: $heading-color;
    font: $heading-font;
  }

  aside {
    @extend %card;
    width: 30%;
    margin-right: 32px;

    h3 {
      margin-top: 0;
    }

    .nav-section {
      padding: 20px 0;

      &:not(:last-child) {
        @include dashedBottomBorder;
      }

      &:last-child {
        padding-bottom: 0;
        padding-top: 0;
      }

      h5 {
        font-size: 16px;
        margin-bottom: 12px;
        margin-top: 0;
      }

      svg {
        width: 19px;
        height: auto;
        stroke: currentColor;
        position: relative;
        top: 4px;
        margin-right: 8px;

        &.logout-icon {
          fill: currentColor;
          margin-right: 10px;
        }

        &.user-icon {
          width: 15px;
          margin-right: 10px;
          margin-left: 2px;
        }

        &.star-icon {
          width: 21px;
        }
      }

      a {
        display: block;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        &.active {
          color: $body-color;
        }
      }
    }
  }

  section {
    @extend %card;
    margin: 8px 16px 24px 16px;
  }

  hr {
    @include dashedBottomBorder;
  }

  .dropdown-navigation {
    margin-bottom: 24px;
  }
}

.membership-details-route {
  h3 {
    margin-top: 0;
  }

  .status {
    display: flex;
    margin-bottom: 8px;

    .saved-card {
      border-left: $divider;
      margin-left: 14px;
      padding-left: 14px;
    }

    svg {
      width: 16px;
      height: 16px;
      padding: 3px;
      border-radius: 100%;
      position: relative;
      top: 3px;
      margin-right: 6px;
    }

    &.enrolled {
      svg {
        background-color: $success-color;
      }
    }

    &.cancelled,
    &.not-enrolled {
      svg {
        background-color: $medium-gray;
      }
    }

    .card-icon {
      width: 23px;
      float: left;
      position: relative;
      top: 4px;
      margin-right: 10px;
    }
  }

  .membership-field {
    margin-bottom: 12px;
  }

  .links {
    margin-top: 12px;

    a {
      display: inline-block;
      margin-right: 24px;
    }
  }

  .qr-code {
    margin-top: 20px;

    img {
      @extend %card;
      margin-top: 20px;
      padding: 0;
    }
  }
}

.my-profile-route {
  p {
    margin-top: 0;
  }

  input, select {
    max-width: 560px;
  }

  button.primary {
    margin-top: 32px;
  }
}

.events-list {
  .session,
  .table-headers {
    display: grid;

    @include desktopExperience {
      grid-template-columns: 68% 1fr 1fr;
      grid-template-rows: 1fr;
      column-gap: 8px;
    }

    @include mobileExperience {
      display: grid;
      grid-template-columns: 80% auto;
      grid-template-rows: auto 1fr;

      .details {
        grid-area: 1 / 1 / 2 / 2;
      }

      .tickets {
        grid-area: 1 / 2 / 2 / 3;
      }

      .date {
        grid-area: 2 / 1 / 3 / 2;
      }

      .view {
        grid-area: 2 / 2 / 3 / 3;
      }
    }
  }

  .session {
    @extend %card;
    margin: 0 8px 16px 8px;

    h5 {
      margin: 0;
    }

    .image {
      width: 70px;
      height: 48px;
      object-fit: cover;
      border-radius: var(--border-radius);
      flex-shrink: 0;
    }

    .details {
      display: flex;
    }

    @include desktopExperience {
      .tickets, .view {
        display: flex;
        align-items: center;
      }

      .image {
        float: left;
        margin-right: 16px;
      }

      .view {
        justify-content: flex-end;
      }
    }

    @include mobileExperience {
      grid-row-gap: 12px;
      margin-left: 0;
      margin-right: 0;

      .image {
        margin-right: 8px;
      }

      .details {
        display: flex;
        word-break: break-word;
      }

      .details, .date {
        padding-right: 8px;
      }

      .details, .tickets {
        padding-bottom: 12px;
        @include dashedBottomBorder;
      }

      .details, .view, .date {
        font: $smaller-font;
      }

      .details, .name {
        padding-right: 12px;
      }
    }
  }

  .table-headers {
    @include mobileExperience {
      grid-template-columns: 77% auto;
      padding: 0 10px;
    }
  }
}

.session-detail-route {
  h4 {
    margin: 0;
    margin-bottom: 8px;
  }

  .navigate-back {
    margin-bottom: 12px;
  }

  .links {
    margin-top: 16px;

    a {
      display: inline-block;
      margin-right: 12px;
    }
  }

  section {
    position: relative;
    padding-right: 90px;

    .event-details {
      display: flex;

      h4 {
        word-break: break-word;
      }

      @include mobileExperience {
        .date, .time {
          font: $smaller-font;
        }

        .date {
          margin-bottom: 6px;
        }
      }

      img {
        width: 116px;
        height: 78px;
        object-fit: cover;
        margin-right: 16px;
        border-radius: var(--border-radius);

        @include mobileExperience {
          width: 78px;
          height: 52px;
        }
      }
    }
  }

  .num-tickets {
    background-color: $light-gray;
    position: absolute;
    right: 16px;
    top: 50px;
    transform: translateY(-50%);
    display: flex;
    line-height: 1;
    align-items: center;
    padding: 5px 14px;
    border-radius: 25px;

    svg {
      width: 18px;
      margin-right: 6px;
      stroke: currentColor;
    }
  }

  .order {
    h5 {
      margin-bottom: 8px;
    }
  }

  .ticket-row {
    @extend %card;
    margin: 0 8px;

    @include mobileExperience {
      .ticket-name {
        padding-right: 8px;

        .type {
          order: 2;
          font: $smaller-font;
        }

        .group {
          order: 1;
          color: $heading-color;
        }
      }

      .code {
        font: $smaller-font;
      }
    }
  }

  .table-headers {
    @include mobileExperience {
      .group {
        display: none;
      }
    }
  }

  .table-headers,
  .ticket-row {
    display: flex;

    .ticket-name {
      display: flex;
    }

    @include mobileExperience {
      align-items: center;

      .ticket-name {
        flex-direction: column;
      }

      .ticket-name, .code {
        width: 50%;
      }
    }

    @include desktopExperience {
      .ticket-name {
        width: 66%;

        .type, .group {
          width: 50%;
        }
      }

      .code {
        width: 33%;
      }
    }
  }

  .ticket-row {
    margin-bottom: 12px;
  }
}

.recent-orders-route {
  .order-row,
  .table-headers {
    display: grid;

    @include desktopExperience {
      grid-template-columns: 1fr 1fr 1fr 40px;
      grid-template-rows: 1fr;
      column-gap: 8px;
      align-items: center;
    }

    @include mobileExperience {
      display: grid;
      grid-template-columns: 50% auto;
      grid-template-rows: auto 1fr;

      .view, .date {
        text-align: right;
      }
    }
  }

  .table-headers {
    @include mobileExperience {
      .quantity {
        display: none;
      }
    }
  }

  .order-row {
    @extend %card;
    margin: 0 16px 16px 16px;

    .order-number {
      font-weight: 600;
    }

    .date {
      font: $smaller-font;
    }

    @include mobileExperience {
      .order-number {
        color: $heading-color;
      }

      .order-number,
      .date {
        @include dashedBottomBorder;
        padding-bottom: 12px;
        margin-bottom: 16px;
      }
    }
  }
}

.order-detail-route {
  .navigate-back {
    margin-bottom: 16px;
  }

  .order-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 8px;
    margin-bottom: 16px;

    @include mobileExperience {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      row-gap: 24px;
      margin-bottom: 24px;
    }

    h5 {
      margin-top: 0;
      margin-bottom: 8px;
    }
  }
}
