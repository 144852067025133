body {
  background: var(--page-background-color);
}

div.message {
  border-radius: var(--border-radius);

  .light-theme & {
    &.members-banner.logged-out {
      background-color: $shade-gray;
    }
  }

  .dark-theme & {
    color: $body-color;

    &.members-banner {
      &.logged-out {
        background-color: rgba(255, 255, 255, 0.06);
      }
    }

    &.expires-in {
      background-color: rgba(255, 255, 255, 0.06);

      svg {
        fill: $primary-contrast-color;
      }
    }
  }

  &.members-banner.logged-in {
    font-weight: 600;
  }

  &.members-banner {
    circle,
    path {
      stroke: var(--primary-contrast-color);
    }
  }
}

.filters {
  border-bottom: $divider;
  padding-bottom: 24px;
  margin-bottom: 32px;

  .date-filter {
    .filter {
      position: relative;
      margin-left: -2px;

      &.active {
        z-index: 1;
      }
    }
  }
}

.event-listing {
  article.event {
    border-bottom: $divider;

    a.title-link {
      h2 {
        margin-bottom: 8px;
      }
    }

    .subtitle {
      margin-bottom: 12px;
    }

    > .button {
      align-self: center;
    }
  }
}

section.cart {
  border-radius: var(--border-radius);

  border: $cart-border;
  background-color: white;

  @include mediumOnly {
    // max-height is set in CartWidget.vue. When it is set it breaks large carts in Tablet view.
    max-height: none !important;
  }

  h2 {
    background-color: $cart-header-background-color;
    color: $cart-header-text-color;
    line-height: 32px;
    border-bottom: $cart-header-border;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  .total-prices {
    ul.discounts-content {
      .discount {
        margin-top: 4px;
      }
    }
  }

  .content {
    .empty-cart-message {
      color: $medium-gray;
    }

    .dark-theme & {
      .empty-cart-message {
        color: rgba(255, 255, 255, 0.4);
      }
    }

    .ticket-date {
      font-size: 13px;
      font-weight: normal;
      color: $body-color;
    }

    .ticket-type {
      margin-bottom: 0;
    }

    .apply-promo-codes {
      .joint-input-button {
        > .wrapper {
          input[type='text'].promo-number-field {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
      }
    }

    button.add-promo-button:enabled {
      color: $primary-color;

      &:hover,
      &:focus {
        color: $primary-color-darker;
      }
    }

    .ticket-wrapper {
      .actions {
        button.remove {
          &:hover,
          &:focus {
            background-color: rgba(255, 255, 255, 0.15);
          }
        }
      }
    }
  }

  button.primary.purchase {
    font-size: 20px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding: 23px 27px;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }
}

.stepper {
  box-shadow: 0px 1px 6px 0px $button-shadow-color;

  > button {
    border-radius: var(--control-border-radius);

    &.less {
      border-right: none;
    }

    &.more {
      border-left: none;
    }
  }

  > .count {
    width: 50px;
  }

  &.active {
    > .count {
      border-color: $secondary-color;
    }
  }
}

.selection {
  h2 {
    margin-bottom: 28px;
  }

  .select-tickets {
  }
}

.select-seats .selected-seats .selected-seats-list .selected-seat {
  // The default is $light-gray, fallback theme's background color is $light-gray
  // so selected seats need a different color to be visible against the background
  background-color: #ededed;
}

.content-detail-page {
  .content-wrapper {
    .description-wrapper {
      .subtitle {
        border-bottom: $divider;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }
    }
  }

  .ticket-group-description-wrapper {
    margin-bottom: 20px;

    > h2 {
      margin-bottom: 4px;
      font-size: 22px;
    }
  }

  .ticket-type-description {
    .subtitle {
      margin-top: 4px;
    }
  }

  .admit-details-field-group {
    margin-bottom: $spacing-m;
  }

  .quantity-first {
    .ticket-group-wrapper {
      margin-bottom: 24px;

      &.name-hidden:not(:first-child) {
        border-top: $divider;
        padding-top: 24px;
        margin-top: 24px;
      }
    }

    .ticket-type {
      .ticket-type-description {
        .subtitle {
          @extend %p;
        }
      }
    }

    section:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .select-tickets {
      margin-bottom: 0;

      .ticket-type:last-child {
        margin-bottom: 0;
      }
    }

    .admit-details-field-group {
      margin-top: 16px;
    }
  }
}

.select-tickets {
  .ticket-type {
    &:not(:last-child) {
      border-bottom: $divider;
      margin-bottom: 24px;
      padding-bottom: 24px;
    }
  }

  .ticket-type,
  .ticket-type-headers {
    &.fixed-price {
      .price-wrapper {
        font-weight: 600;
      }
    }

    &.flexible-price {
      .form-input .label {
        font-weight: 600;
      }
    }
  }
}

.checkout {
  h2 {
    @include mobileExperience {
      font-size: 18px;
      line-height: 24px;
    }
  }

  .purchaser-details,
  .upsell,
  .payment,
  .terms-conditions {
    &:not(:last-child) {
      border-bottom: $divider;
      padding-bottom: 40px;
      margin-bottom: 40px;
    }
  }
}

input[type='checkbox'] {
  width: 23px;
  height: 22px;

  .light-theme & {
    border-color: $light-gray;
    background-color: white;

    &:hover {
      border-color: $medium-gray;
    }

    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  .dark-theme & {
    border-color: rgba(255, 255, 255, 0.15);
    border-width: 2px;
    background-color: rgba(255, 255, 255, 0.06);

    &:checked {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

.complete {
  .main {
    border-radius: var(--border-radius);
    padding: 0 32px 32px;
    box-shadow: 0px 1px 6px 0px $button-shadow-color;

    .dark-theme & {
      background-color: var(--page-background-color);

      h1 {
        color: $heading-color;

        span {
          color: rgba(255, 255, 255, 0.87);
        }
      }
    }

    .light-theme & {
      h1 {
        span {
          color: $dark-gray;
        }
      }
    }

    h1 {
      span {
        margin-bottom: 8px;
      }
    }
    code.order-number {
      .light-theme & {
        color: $dark-gray;
      }

      .dark-theme & {
        margin: 4px 0 16px;
      }
    }
  }
}

.donation,
.gift-cards {
  .radio-group.ticket-types {
    .radio-item {
      .label {
        @extend %h3;
        display: inline-block;
        margin-bottom: 4px;
      }
    }

    .ticket-type {
      margin-bottom: 24px;

      .currency-symbol {
        font-weight: 600;
        color: $dark-gray;

        .dark-theme & {
          color: rgba(255, 255, 255, 0.87);
        }
      }
    }
  }
}

.redeem-membership-form .membership-level {
  .radio-item {
    &:first-child {
      border-radius: var(--border-radius);
    }
  }
}

.membership-roles,
.auto-renew {
  h3.label {
    margin-bottom: 16px;
  }

  label.select.unnamed-members {
    span.label {
      @extend %h3;
      margin-bottom: 8px;
    }
  }

  .member-names {
    .name-fields {
      margin-top: 12px;
    }
  }
}

.content-detail-page.membership {
  .redeem-membership-route,
  .buy-membership {
    .section-header {
      margin-top: 24px;
    }

    .membership-level .section-header {
      margin-top: 0;
    }
  }
}

.auto-renew {
  > h2 {
    margin-top: 0;
    margin-bottom: 8px;
  }

  > p {
    margin: 0;
  }

  .radio-group {
    margin-top: 24px;
  }
}

.edit-order {
  .session {
    border-radius: var(--control-border-radius);
    background-color: white;
    overflow: hidden;

    .dark-theme & {
      background-color: transparent;

      .details h4 {
        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .reschedule-event,
  .cancel-event {
    font-weight: 600;
  }

  .ticket {
    font-size: 16px;
  }
}

.home-route {
  .resend-tickets {
    margin: 56px 0;
  }
}

.modal {
  .terms-and-conditions-dialog .terms-and-conditions-content {
    .dark-theme & {
      border-color: rgba(255, 255, 255, 0.2);
    }
  }
}

.modal {
  .modal-window {
    border-radius: var(--border-radius);

    .dark-theme & {
      box-shadow: 3px 5px 20px black;
    }
  }

  .modal-header {
    &.has-title {
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }

    .close {
      .svg-icon {
        width: 24px;
        height: 24px;

        .dark-theme &:hover,
        .dark-theme &:focus {
          stroke: rgba(255, 255, 255, 0.5);
        }
      }

      @include desktopExperience {
        margin-right: -5px;
      }
    }
  }

  &.mobile-drawer {
    .modal-window {
      @include mobileExperience {
        border-radius: var(--border-radius) var(--border-radius) 0 0;
      }
    }
  }
}

details.member-menu ul.member-menu-items {
  .dark-theme & {
    background-color: var(--page-background-color-lighter);
  }
}

.autocomplete-results {
  .dark-theme & {
    background-color: var(--page-background-color-lighter);
    border-color: rgba(255, 255, 255, 0.3);

    .autocomplete-after-list-slot,
    .autocomplete-list-item {
      border-color: rgba(255, 255, 255, 0.3);
    }
  }
}

.select-seats {
  .dark-theme & {
    color: black;

    .selected-seats .selected-seats-list .selected-seat {
      background-color: rgba(255, 255, 255, 0.1);
      color: $body-color;
    }
  }
}

.forgot-membership-route {
  .form-input input[type='email'] {
    max-width: 768px;
  }
}

.selectable-button {
  border-radius: var(--control-border-radius);

  .dark-theme & {
    .remaining:not(.danger),
    .session-price {
      color: rgba(255, 255, 255, 0.4);
    }

    .session-time {
      color: rgba(255, 255, 255, 0.8);
    }
  }
}

.selected-date-time {
  border-radius: var(--control-border-radius);
}

.section-header {
  .dark-theme & {
    background-color: rgba(255, 255, 255, 0.06);
  }
}

.dark-theme .date-picker button.more-dates {
  transition: background-color 200ms ease-out;
}

.password-requirements {
  border-radius: var(--border-radius);
}

.schedule-payments-wrapper {
  border-radius: var(--border-radius);

  .payment-amount-options {
    border-radius: var(--border-radius);
  }

  .schedule-payments-amount {
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
  }

  .schedule-payments-all-payments {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  .completed-payments,
  .scheduled-payments {
    .table-wrapper {
      border-radius: var(--border-radius);
    }
  }
}

.dropdown-navigation {
  .vs__dropdown-toggle {
    border-radius: 2px;

    .dark-theme & {
      box-shadow: 0 1px 6px rgb(255 255 255 / 6%);
      background-color: rgba(255, 255, 255, 0.06);
      outline: 3px solid rgb(255 255 255 / 10%);
    }

    .vs__selected svg {
      color: $gold;
    }
  }

  .vs__dropdown-option--selected,
  .vs__dropdown-option--highlight {
    svg {
      color: $gold;
    }

    .dark-theme & {
      background-color: rgba(255, 255, 255, 0.06);
      color: white;
    }
  }
}

.my-account-wrapper {
  aside .nav-section {
    a.active {
      svg {
        color: $gold;
      }
    }
  }
}

.session-detail-route {
  .dark-theme & {
    .num-tickets {
      background-color: rgba(255, 255, 255, 0.06);
    }
  }
}
