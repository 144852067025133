*,
:before,
:after {
  box-sizing: border-box;
}

body {
  font: $body-font;
}

figure {
  margin: 0;

  img {
    width: 100%;
    display: block;
  }
}

img {
  max-width: 100%;
}

hr {
  border: none;
  border-bottom: $divider;
  margin: 0;
}

h1 {
  @extend %h1;
}

h2 {
  @extend %h2;
}

h3 {
  @extend %h3;
}

h4 {
  @extend %h4;
}

h5 {
  @extend %h5;
}

h6 {
  @extend %h6;
}

p {
  @extend %p;
}

// Scoped to content only so that it does not affect the membership menu.
.site-content details {
  > summary {
    @extend %secondary-button;

    &:after {
      content: '';
      background-image: dropdownArrow($primary-color);
      background-repeat: no-repeat;
      background-size: 10px;
      width: 10px;
      height: 12px;
      display: inline-block;
      margin-left: 8px;
      position: relative;
      top: 4px;
    }

    &::-webkit-details-marker {
      display: none;
    }
  }

  &[open] summary {
    &:after {
      transform: rotate(180deg);
      top: -3px;
    }
  }
}

.section-header {
  background-color: rgba(0,0,0,0.04);
  margin-top: 0;
  margin-bottom: 16px;
  padding: 14px 16px;

  p {
    margin: 4px 0 0;
    font: $smaller-font;
  }

  h3 {
    @extend %h5;
    margin: 0;
  }

  @include mobileExperience {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.availability-status {
  font-size: 14px;
  color: $body-color;

  .dark-theme & {
    color: rgba(255,255,255,0.6);
  }

  &.danger {
    color: $error-color;
  }
}

.dropdown-navigation {
  .vs__dropdown-toggle {
    svg.triangle-icon {
      width: 10px;
    }
  }

  .navigation-item {
    display: flex;
  }

  svg {
    width: 22px;
    height: auto;
    max-height: 22px;
    margin-right: 14px;
    position: relative;
    stroke: currentColor;

    &.user-icon {
      max-height: 19px;
    }

    &.logout-icon {
      fill: currentColor;
    }

    &.triangle-icon {
      fill: currentColor;
    }
  }
}

@import 'components/selectable_button';
@import 'components/selected_date_time';
@import 'components/password_reset';
@import 'components/scheduled_payments';
@import '../../node_modules/vue-select/dist/vue-select';
