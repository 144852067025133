@include small {
  .long-label {
    display: none;
  }
}

@include mediumAndLarge {
  .short-label {
    display: none;
  }
}

@include print {
  .short-label {
    display: none;
  }
}

// Account links layout.
.account-links {
  // Keep links aligned right when they wrap onto two lines.
  text-align: right;

  // Never wrap link text.
  > a {
    display: inline-block;
    white-space: nowrap;
  }

  // Allow the right margin of the links to overflow the container so that the
  // right edge of the text is aligned with right edge of the page container.
  margin-right: -16px;
  > a {
    padding: 4px 16px;
  }

  // Less whitespace on smaller layouts.
  @include small {
    margin-right: -8px;
    > a {
      padding: 4px 8px;
    }
  }
}

details.member-menu {
  // Full height flex-item.
  align-self: stretch;

  @include mediumAndLarge {
    position: relative;
  }

  summary.member-menu-icon {
    display: inline-flex;
    align-items: center;
    border: none;
    cursor: pointer;
    padding-left: 16px;
    height: 100%;

    svg {
      stroke: $members-menu-icon-color;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &::marker {
      display: none;
      content: '';
      width: 0;
      height: 0;
    }
  }

  ul.member-menu-items {
    position: absolute;
    z-index: 1;
    padding: 0 16px;
    margin: 0;
    background: $white;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    list-style: none;
    text-align: left;

    @include small {
      left: 0;
      width: 100vw;
      transform: translateY(-4px);
    }

    @include mediumAndLarge {
      top: calc(100%);
      right: 0;
      width: 352px;
    }

    li {
      display: flex;
      padding: 16px 0;
      align-items: center;
      flex-wrap: wrap;
      text-align: left;

      &.member-menu-greeting {
        height: 80px;
        flex-flow: column;
        justify-content: center;
      }

      &:not(:last-of-type) {
        border-bottom: $divider;
      }

      button {
        font: $button-font;
        margin: 0;
        padding: 0;
        border: none;
        color: $members-menu-text-color;
        background-color: transparent;
        display: flex;
        align-items: center;
      }

      .account,
      .membership,
      .logout {
        svg {
          margin-right: 8px;
          width: 23px;
          stroke: currentColor;

          * {
            stroke: inherit;
          }
        }
      }
    }
  }

  .greeting-title {
    color: $members-menu-text-color;
    font-weight: bold;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    width: 100%;
  }

  .greeting-username {
    color: $members-menu-username-color;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    width: 100%;
  }
}
